import React from "react";
import { Button } from "components";
import { RejectWarningModal } from "modals";
import { useModal } from "hooks";

export const FlagButtonGroup = ({
  isSubmitting,
  isFetching,
  onDoNotEnter = () => {},
  onSkip = () => {},
  onReject = () => {},
  onApprove = () => {},
  onNext = () => {},
  isDoNotEnter,
  isSkipped,
  isRejected,
  isApproved,
  isAllOfferValid,
  isSubmitDisabled,
  ShowNext
}) => {
  const rejectModal = useModal();

  return (
    <>
      <Button
        buttonTheme={`${isDoNotEnter ? "warn" : "dark"}`}
        width="160px"
        size="small"
        className="ml-2 my-auto"
        onClick={onDoNotEnter}
        isLoading={isSubmitting}
        disabled={isFetching}
        eventType="flag"
      >
        {isDoNotEnter ? "NO ENTRY" : "DO NOT ENTER"}
      </Button>
      
      {/* <Button
        buttonTheme={`${isSkipped ? "warn" : "dark"}`}
        width="100px"
        size="small"
        className="ml-2 my-auto"
        onClick={onSkip}
        isLoading={isSubmitting}
        disabled={isFetching}
        eventType="flag"
      >
        {isSkipped ? "SKIPPED" : "SKIP"}
      </Button> */}

      <Button
        buttonTheme={`${isRejected ? "error" : "dark"}`}
        width="100px"
        size="small"
        className="ml-2 my-auto"
        onClick={() => rejectModal.openModal()}
        isLoading={isSubmitting}
        disabled={isFetching}
        eventType="flag"
      >
        {isRejected ? "REJECTED" : "REJECT"}
      </Button>

      <Button
        buttonTheme={`${
          isApproved ? "green" : isAllOfferValid ? "primary" : "dark"
        }`}
        width="120px"
        size="small"
        className="ml-2 my-auto"
        onClick={onApprove}
        isLoading={isSubmitting}
        disabled={isFetching || isSubmitDisabled}
        eventType="flag"
      >
        {isApproved ? "APPROVED" : "APPROVE"}
      </Button>
      { ShowNext ?
      <Button
        buttonTheme={"dark"}
        width="120px"
        size="small"
        className="ml-2 my-auto"
        onClick={onNext}
        isLoading={isSubmitting}
        disabled={isFetching || !isApproved}
        eventType="flag"
      >
        NEXT
      </Button> : null }
      <RejectWarningModal 
        {...rejectModal} 
        onReject={(reject_message, remove_children) => 
          onReject(reject_message, remove_children)} 
      />
    </>
  );
};
