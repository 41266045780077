/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useMainContext } from "contexts";
import {
  FaCheckCircle,
  FaMinusCircle,
  FaStopCircle,
  FaArrowRight,
} from "react-icons/fa";
import { getPageCrops } from "api";

import "./style.scss";

export const AdBlockDropDown = ({
  className,
  placeholder = "Select an AdBlock",
  width = 300,
  onSelectValue = () => {},
  id = "Adblock DropDown",
  value,
  isVisible,
  onNoAdblocks = () => {},
  onLoad = () => {},
  type = "offer",
  adblockData = [],
  readOnly = false,
  disabled = false
}) => {
  const menuRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const { postPageEvents } = useMainContext();

  useOutsideClick(menuRef, clickOutsideMenu);

  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params, data) => {
    onSelectValue(params, data);
    setShowResults(false);
  };

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
  };

  const handleFetch = async () => {
    setIsLoading(true);
    const result = await getPageCrops(id);
    const newData = result?.payload?.adblocks || [];
    onLoad(newData);
    setData(newData);
    if (newData.length > 0) {
      handleChange(
        value && newData.find((item) => item.uuid === value)?.uuid
          ? newData.find((item) => item.uuid === value)?.uuid
          : newData[0].uuid,
        newData
      );
    } else {
      handleChange("", []);
      onNoAdblocks();
    }
    setIsEmpty(newData.length === 0);
    setIsLoading(false);
  };

  useEffect(() => {
    if (id) {
      handleFetch();
    }
    return () => {};
  }, [id]);

  useEffect(() => {
    setData(adblockData);
    return () => {};
  }, [adblockData]);

  const handleInputClick = () => {
    !readOnly && setShowResults(true);
    postPageEvents("focus", id);

    setTimeout(() => {
      const divToScrollTo = document.getElementById(value);
    
      if (divToScrollTo) {
        divToScrollTo.scrollIntoView();
      }

      /*scroller.scrollTo(`#${value}`, {
        duration: 500,
        delay: 100,
        smooth: "easeInOutQuint",
        containerId: "adblock-dropdown",
      });*/
    }, 0);
  };

  const redIcon = {
    color: "#ff8484",
  };
  const greenIcon = {
    color: "#63ce6b",
  };
  const yellowIcon = {
    color: "#fff0a8",
  };

  return (
    <div className={classNames(className, isVisible ? "" : "d-none")}>
      <div className="adblock-dropdown" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          <div className="d-flex w-100" onClick={() => !disabled ? handleInputClick() : null}>
            {isLoading ? (
              <StyledInput type="text" value={"Loading..."} readonly />
            ) : isEmpty ? (
              <StyledInput type="text" value={"No Adblocks"} readonly />
            ) : (
              <>
                <StyledInput
                  type="text"
                  aria-labelledby={"auto complete"}
                  onFocus={() => setFocused(true)}
                  onBlur={handleBlur}
                  aria-describedby={"auto complete"}
                  value={data?.length > 0 ? value : ""}
                  placeholder={placeholder}
                  onChange={(e) => {}}
                  readonly
                  disabled={disabled}
                />

                {showResults ? (
                  <AiFillCaretUp
                    color="white"
                    className="menu-button__drop-down"
                    alt="drop-down"
                  />
                ) : (
                  <AiFillCaretDown
                    color="white"
                    className="menu-button__drop-down"
                    alt="drop-down"
                  />
                )}
              </>
            )}
          </div>

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div ref={menuRef} id="adblock-dropdown" className="menu-dropdown">
            {isLoading ? (
              <LoadMore>Loading...</LoadMore>
            ) : (
              data.map((item, index) => {
                return (
                  <div
                    className={classNames(
                      "menu-item",
                      item?.uuid === value ? "active" : ""
                    )}
                    key={`key${index}`}
                    id={item.uuid}
                    onClick={() => {
                      handleChange(item.uuid, data);
                      postPageEvents("click", "Adblock Dropdown");
                    }}
                  >
                    <span>{`${item?.uuid}`}</span>
                    <span className="status">
                      {(item?.offers_approved && type === "offer") ||
                      (item?.products_approved && type === "product") ? (
                        <FaCheckCircle style={greenIcon} />
                      ) : (item?.offers_rejected && type === "offer") ||
                        (item?.products_rejected && type === "product") ? (
                        <FaMinusCircle style={redIcon} />
                      ) : item?.skipped ? (
                        <FaArrowRight style={yellowIcon} />
                      ) : item?.rejected ? (
                        <FaMinusCircle style={redIcon} />
                      ) : item?.do_not_enter_f ? (
                        <FaStopCircle style={yellowIcon} />
                      ) : null}
                    </span>
                  </div>
                );
              })
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  cursor: pointer;

  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const LoadMore = styled.div`
  color: ${(props) => props.theme.palette.primaryOutline};
  cursor: pointer;
  transition: all 300ms ease;
  font-size: 16px;
  text-align: center;
  &:hover {
    opacity: 0.7;
  }
`;
