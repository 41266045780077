/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveTradeInSavingsTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const type = useInput("$");
  const val = useInput("10.00");
  const suffix = useInput("or more");

  const typeData = [
    { id: "$", title: "$" },
    { id: "%", title: "%" },
    { id: "X", title: "X" },
  ];
  const suffixData = [
    { id: "or more", title: "or more" },
    { id: "or less", title: "or less" },
  ];

  const handleUpdateValue = (isUT, v, t, s) => {
    onChange(
      `${isUT ? "Up To" : ""} ${
        t === "$" ? `${t}${v}` : `${v}${t}`
      } ${s} trade in savings`
    );
  };

  useEffect(() => {
    if (value.endsWith("trade in savings")) {
      let main = value.slice(0, -16).trim();
      if (main.startsWith("Up To")) {
        isUpTo.setValue(true);
        main = main.slice(5).trim();
      } else {
        isUpTo.setValue(false);
      }
      const sufIndex = main.indexOf(" ");
      const tv = main.slice(0, sufIndex).trim();
      suffix.setValue(main.slice(sufIndex).trim());
      if (tv.startsWith("$")) {
        type.setValue("$");
        val.setValue(tv.slice(1));
      } else {
        type.setValue(tv.slice(-1).trim());
        val.setValue(tv.slice(0, -1).trim());
      }
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <ToggleButton
          className="mt-auto mb-2"
          label="Up To"
          value={isUpTo.value}
          style={{ width: 50 }}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(e, val.value, type.value, suffix.value);
          }}
        />
        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(isUpTo.value, e, type.value, suffix.value);
            }}
          />
        </div>
        <div style={{ width: 50, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(isUpTo.value, val.value, e, suffix.value);
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Suffix"
            data={suffixData}
            width={70}
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(isUpTo.value, val.value, type.value, e);
            }}
          />
        </div>
        <div className="mt-auto mb-1 ml-2" style={{ fontSize: 12 }}>
          Trade In Savings
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
