/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, Button } from "components";
import { useInput } from "hooks";
import { useMutation } from "react-query";
import { createModelNumberApi, createOriginApi, createVarietyApi } from "api";
import { useMainContext } from "contexts";

// Based on PRODUCT_INFO_FIELDS
const MOVFieldMapper = {
  'brand_model_number': 'Model Number',
  'brand_origin': 'Origin',
  'variant_id': 'Variety'
};

const apiMapper = {
  'brand_model_number': createModelNumberApi,
  'brand_origin': createOriginApi,
  'variant_id': createVarietyApi
};

const generateRequestObj = (whichMOV, name, brand_i, sub_cat_i) => {
  const commonFields = {
    ext_id: brand_i,
    sub_cat_i: sub_cat_i
  };
  switch (whichMOV) {
    case 'brand_model_number':
      return { ...commonFields, model_number: name };
    case 'brand_origin':
      return { ...commonFields, origin_nm: name };
    case 'variant_id':
      return { ...commonFields, variety_nm: name };
    default:
      return {};
  }
};

export const CreateMOV = ({
  movName,
  brand_i,
  whichMOV,
  sub_cat_i,
  onCancel = () => { },
  onOk = () => { },
}) => {
  const name = useInput("");
  const { postPageEvents } = useMainContext();

  const createMutation = useMutation(apiMapper[whichMOV]);

  useEffect(() => {
    name.setValue(movName);
  }, [movName, sub_cat_i]);

  const handleCreate = async () => {
    postPageEvents("click", `Create ${MOVFieldMapper[whichMOV]}`);
    const requestObj = generateRequestObj(whichMOV, name.value, brand_i, sub_cat_i)
    const res = await createMutation.mutateAsync(requestObj);
    onOk(res.payload);
  };

  const handleCancel = () => {
    postPageEvents("click", `Cancel Create ${MOVFieldMapper[whichMOV]}`);
    onCancel();
  };

  return (
    <Container>
      <Header>CREATE {MOVFieldMapper[whichMOV]}</Header>
      <Content>
        <Text className="mb-2">
          Please make sure the {MOVFieldMapper[whichMOV]} does not already exist and it will be created under selected Brand.
        </Text>
        <Input2 label={`New ${MOVFieldMapper[whichMOV]} Name`} className="mt-2" {...name} />

        <div className="d-flex mt-4">
          <Button
            size="small"
            buttonTheme="dark"
            className="ml-auto"
            width="100px"
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            size="small"
            buttonTheme="dark"
            className="ml-2"
            width="auto"
            onClick={handleCreate}
            isLoading={createMutation.isLoading}
            disabled={!name.value}
          >
            CREATE {MOVFieldMapper[whichMOV]}
          </Button>
        </div>
      </Content>
    </Container>
  );
};
const Text = styled.div`
  font-size: 14px;
`;
const Container = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  color: white;
`;
