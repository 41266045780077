/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { MainLayout } from "layouts";
import { useModal } from "hooks";
import { PAGE_TYPES } from "config";
import {
  Button,
  Alert,
  PageDropDown,
  Link,
  ColorPick,
  AdblockToolButton,
  AdblockIncentives,
  SectionItem,
  AdblockItem,
  GroupItem,
  DropDownMenu,
  DigitalCouponCheck,
} from "components";

import { EndQueueModal, RetryCroppingModal, RejectPageWarning, ApprovePageNoAdblockWarning, DeleteWarningModal } from "modals";
import { splitIncentives, setCachedImage, getCachedImage } from "utils";
import { useParams, navigate, useLocation } from "@reach/router";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { FaHourglass, FaTrash } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import {
  getImage,
  getPageCrops,
  approvePage,
  getCircularById,
  rejectPage,
  postPageAutoCrop,
  getPageSections,
  savePageIncentiveString,
  saveCircularIncentiveString,
  deleteAdBlockById,
  deleteSectionById,
  addPageToQueue,
  getPageGroups,
  createDoubletruckPage,
  unsetDoubletruckPage,
  getPageByObjId,
  getCircularByObjID,
  deleteGroupById,
  deletePageById,
  getCurrentUserAPI,
  getPageById,
  createDoubletruckPageVerical
} from "api";

import styled from "styled-components";
import useKeypress from "react-use-keypress";
import "cropperjs/dist/cropper.css";
import { fabric } from "fabric";
import { useAuthContext, useMainContext } from "contexts";
import { format } from "date-fns";
import { VscSplitHorizontal, VscSplitVertical } from "react-icons/vsc";

const OUTLINE_COLORS = ["blue", "red", "yellow"];

export const AdBlockPage = () => {
  const params = useParams();
  const blockRef = useRef();
  const location = useLocation();

  const {
    currentCircularId,
    currentPageId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    setSessionId,
    setLiveCounterData
  } = useMainContext();

  //Loading states
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [isAdCropping, setIsAdCropping] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isApproveDisabled, setIsApproveDisabled] = useState(false);
  //const [isRejected, setIsRejected] = useState(false);

  //Circular data
  const [circularName, setCircularName] = useState("");

  //Page data
  const [pagesData, setpagesData] = useState([]);
  const [pageData, setpageData] = useState([]);
  const [pageType, setpageType] = useState("");
  const [parentPageData, setparentPageData] = useState([]);
  const [parentPageCircularData, setparentPageCircularData] = useState([]);
  const [isDoubleTruck, setIsDoubleTruck] = useState(false);

  //Adblock data
  const [adBlockData, setAdBlockData] = useState([]);
  const [type, setType] = useState("adblock");

  // Canvas information
  const [canvas, setCanvas] = useState("");

  const [activeCanvasObject, setActiveCanvasObject] = useState(null);
  const [canvasObjects, setCanvasObjects] = useState([]);
  const [canvasRatio, setCanvasRatio] = useState(1);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [currentCanvasObjects, setCurrentCanvasObjects] = useState([]);

  //Error messages
  const [errorText, setErrorText] = useState("");
  const [canvasErrorMessage, setCanvasErrorMessage] = useState("");

  // Digital Coupon flag
  const [digitalCouponApplied, setDigitalCouponApplied] = useState(false);

  //Modals and user information
  const alertModal = useModal();
  const retryCroppingModal = useModal();
  const rejectPageModal = useModal();
  const endQueueModal = useModal();
  const approvePageWithNoAdblockModal = useModal();
  const deletePageModal = useModal();
  const { userInfo } = useAuthContext();

  // View info
  const [currentAdblockId, setCurrentAdblockId] = useState("");
  const [currentOutlineColor, setCurrentOutlineColor] = useState(
    OUTLINE_COLORS[0]
  );
  var started = false;
  var x = 0;
  var y = 0;

  // Incentive data
  const [incentiveData, setIncentiveData] = useState([]);
  const handleAddIncentive = (newData) => {
    let allItems = [...incentiveData];
    for(var i = 0; i < newData.length; i++) {
      allItems = [ ...allItems, { type: newData[i].attach_to.toString().toLowerCase(), text: newData[i].incentive_text } ];
    }
    setIncentiveData(allItems);
  };
  const handleRemoveIncentive = (index) => {
    let temp = [...incentiveData];
    temp.splice(index, 1);
    setIncentiveData(temp);
  };
  const handleUpdateIncentive = (index, data) => {
    let temp = [...incentiveData];
    temp.splice(index, 1, data);
    setIncentiveData(temp);
  };

  // Section Data
  const handleSectionSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
      setSelectedIndex(index);
      await postPageEvents("click", `Section ${index}`);
    }
  };

  // Section Data
  const handleGroupSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
      setSelectedIndex(index);
      await postPageEvents("click", `Section ${index}`);
    }
  };

  /*
  ///////////////////////////////////////
  ////// Start Methods //////////////////
  /////////////////////////////////////
  */

  const sortBy = ['group','section','adblock']
  const customSort = ({data, sortBy, sortField}) => {
    const sortByObject = sortBy.reduce((obj, item, index) => {
      return {
        ...obj,
        [item]: index
      }
    }, {})
    return data.sort((a, b) => sortByObject[a[sortField]] - sortByObject[b[sortField]]);
  }
  
  //const sortAdblocks = (a,b)=> (b.rejected - a.rejected);

  const init = async (type) => {
    // display dotted boundary when over rect
    canvas.on("mouse:over", function (e) {
      handleCanvisObjectMouseOver(canvas, e);
    });
    // remove dotted boundary when over rect
    canvas.on("mouse:out", function (e) {
      if (e?.target) {
        e.target.set("strokeDashArray", null);

        if (canvas.status === "crop") {
          e.target.hoverCursor = "crosshair";
        } else {
          e.target.hoverCursor = "default";
        }

        canvas.renderAll();
      }
    });
    // zoom when mouse wheel
    canvas.on("mouse:wheel", function (opt) {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
      var vpt = this.viewportTransform;
      if (canvas.getHeight() > blockRef.current.offsetHeight) {
        vpt[4] = 200 - (1000 * zoom) / 2;
        vpt[5] = 200 - (1000 * zoom) / 2;
      }
    });
    // pan when alt key & mouse down
    canvas.off('mouse:down')
    canvas.on("mouse:down", function (opt) {
      const evt = opt.e;
      if (evt.altKey === true) {
        this.isDragging = true;
        this.selection = false;
        this.lastPosX = evt.clientX;
        this.lastPosY = evt.clientY;
      } else if (evt.button === 2) {
        var mouse = canvas.getPointer(evt);
        started = true;
        x = mouse.x;
        y = mouse.y;

        var today = new Date();
        this.isCreating = true;
        //console.log('type', type);
        var rect = new fabric.Rect({
          width: 0,
          height: 0,
          left: x,
          top: y,
          stroke: currentOutlineColor,
          strokeWidth: 1,
          fill: "transparent",
          cornerColor: "white",
          cornerStrokeColor: currentOutlineColor,
          id: "temp-" + today.getTime(),
          type,
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
        canvas.renderAll();
        canvas.setActiveObject(rect);
      }
    });

    canvas.on("mouse:move", function (opt) {
      if (this.isDragging) {
        var e = opt.e;
        var vpt = this.viewportTransform;
        vpt[4] += e.clientX - this.lastPosX;
        vpt[5] += e.clientY - this.lastPosY;
        this.requestRenderAll();
        this.lastPosX = e.clientX;
        this.lastPosY = e.clientY;
      } else if (this.isCreating) {
        // Resize the newly created crop while mouse draging
        if (!started) {
          return false;
        }

        let mouse = canvas.getPointer(opt.e);

        let w = mouse.x - x,
          h = mouse.y - y;

        if (!w || !h) {
          return false;
        }

        let rect = canvas.getActiveObject();
        if (rect) {
          if (opt.e.shiftKey) {
            rect.set("width", Math.min(w, h)).set("height", Math.min(w, h));
          } else {
            rect.set("width", w).set("height", h);
          }
          rect.setControlsVisibility({ mtr: false });
        }
        canvas.renderAll();
      }
    });

    canvas.on("mouse:up", function (opt) {
      const evt = opt.e;
      this.setViewportTransform(this.viewportTransform);
      this.isDragging = false;
      this.selection = true;
      this.isCreating = false;
      if (evt.button === 2) {
        if (started) {
          started = false;
        }
        // Remove if the rect is too small
        var objects = canvas.getObjects();
        objects.forEach((rect) => {
          if (
            rect &&
            (Math.abs(rect?.width) < 20 || Math.abs(rect.height) < 20)
          ) {
            // Instead of deleting the crops, adding message to the crops that the crop size is less than threshold
            // canvas.remove(rect);
            rect.set("crop_message", true)

            // setCanvas(canvas);
          }
        });
        canvas.requestRenderAll();
        
        // Disable all objects unselectable
        canvas.discardActiveObject();
        objects = canvas.getObjects();

        let newCanvasObjects = objects.map((object, index) => {
          return {
            index: index,
            type: object?.type,
            uuid: object?.id,
            rejected: object?.rejected,
            approved: object?.approved,
            group_text: object?.group_text,
            group_type: object?.group_type,
            reject_message: object?.reject_message,
            crop_message: object?.crop_message
          };
        })

        newCanvasObjects = customSort({data:newCanvasObjects, sortBy, sortField: 'type'});

        //Create the adblocks list
        setCanvasObjects(newCanvasObjects);
      }
    });

    //
    canvas.on("selection:created", function (opt) {
      console.log(opt)
      if (opt.selected){
        setCurrentCanvasObjects(opt.selected.length > 0 ? opt.selected : [])
      }
      setCurrentAdblockId(opt.target.id);
      setActiveCanvasObject(opt.selected[0]);
      // if (canvas.status === "move") {
      //   opt.target.hoverCursor = "move";
      // }
    });

    canvas.on("selection:updated", function (opt) {
      console.log(opt)
      console.log("selection:updated", opt.target.id);
      if (opt.selected){
        setCurrentCanvasObjects(opt.selected.length > 0 ? opt.selected : [])
      }
      if (opt.target) {
        if (opt.target._objects?.length > 0){
          setCurrentCanvasObjects(opt.target._objects)
        }
      }
      setCurrentAdblockId(opt.target.id);
      setActiveCanvasObject(opt.selected[0]);
      // if (canvas.status === "move") {
      //   opt.target.hoverCursor = "move";
      // }
    });

    canvas.on("selection:cleared", function (opt) {
      console.log("selection:cleared", opt);
      setCurrentCanvasObjects([]);
      setActiveCanvasObject(null);
    });
  };

  /*
  ///////////////////////////////////////
  ////// Watchers //////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    setCurrentPage("LR - Adblock");
    setPreviousTime(new Date());
    setIsLoading(false);
    setSessionId(String(new Date()));

    const canvi = new fabric.Canvas("canvas", {
      height: blockRef.current.offsetHeight,
      width: blockRef.current.offsetWidth,
      fireRightClick: true, // <-- enable firing of right click events
      stopContextMenu: true, // <--  prevent context menu from showing
    });

    fabric.Object.prototype.transparentCorners = false;
    fabric.Object.prototype.cornerColor = "white";
    fabric.Object.prototype.cornerStrokeColor = currentOutlineColor;
    fabric.Object.prototype.cornerStyle = "circle";
    fabric.Object.prototype.cornerSize = 8;
    setCanvas(canvi);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (canvas) {
      init(type);
    }

    return () => {};
  }, [type, canvas]);

  // useEffect(() => {
  //   if (canvasObjects.length > 0) {
  //     setIsRejected(false);

  //     if(canvasObjects.filter((currentItem) => currentItem.rejected === true).length > 0) {
  //       setIsRejected(true);
  //     }

  //     //console.log('canvasObjects', canvasObjects);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [canvasObjects]);

  useEffect(() => {
    //If deeplink and we have both the circular and the page, go to them
    if (params?.circular !== "0" && params?.page !== "0") {
      setCurrentCircularId(params?.circular);
      setCurrentPageId(params?.page);

      //If we only know the circular like we normally do from the circular list page, select the first page
    } else if (params?.circular !== "0" && params?.page === "0") {
      setCurrentCircularId(params?.circular);
      setCurrentPageId("");
    }

    //TODO: we need to do something if the user gets to this page without any params

    return () => {};
  }, [params?.circular, params?.page]);

  useEffect(() => {
    if (currentCircularId) {
      fetchCircular();
    }
    return () => {};
  }, [currentCircularId]);


  useEffect(() => {
    if (currentAdblockId) {
      console.log("currentAdblockId", currentAdblockId);
    }
    return () => {};
  }, [currentAdblockId, digitalCouponApplied]);

  // useEffect(() => {
  //   if (canvasObjects) {

  //     canvasObjects.sort(sortAdblocks)
  //     setCanvasObjects(canvasObjects);

  //   }
  //   return () => {};
  // }, [canvasObjects]);


  /*
  ///////////////////////////////////////
  ////// Fetch Data //////////////////
  /////////////////////////////////////
  */

  const fetchCircular = async () => {
    const result = await getCircularById(currentCircularId);
    if (result.success) {
      setCircularName(
        result.payload.circ_subtype +
          "_" +
          result.payload.retailer.name +
          "_" +
          format(
            new Date(
              result.payload.circ_week_dt.replace(/-/g, "/").replace(/T.+/, "")
            ),
            "MM/dd/yyyy"
          ) +
          `_${result.payload.ext_id}` +
          `_${result.payload.circ_version ? result.payload.circ_version : 0}`
      );
    } else {
      //TODO: set error message about no circular matching
    }
  };

  const onPageSelect = async (params, pages = [], page) => {
    setCurrentPageId(params);
    
    navigate(`/adblock/${currentCircularId}/${params}`);
    if (page) {
      setpageData(page);
      setCanvasErrorMessage('');

      let tempIncentives = [];
      const pageData = await getPageById(params);
      if (pageData?.success) {
        const page_type = PAGE_TYPES.find((item) => item.page_c === pageData.payload.page_loc);
        setpageType(page_type.page_nm);

        if (pageData.payload?.incentive_nm) {
          tempIncentives = [
            ...splitIncentives(pageData.payload?.incentive_nm).map((item) => ({
              text: item,
              type: "page",
            })),
          ];
        }
        setDigitalCouponApplied(Boolean(pageData.payload?.digital_coupon_f))
      }

      const circularData = await getCircularById(currentCircularId);
      if (circularData?.success && circularData.payload?.incentive_nm) {
        tempIncentives = [
          ...tempIncentives,
          ...splitIncentives(circularData.payload?.incentive_nm).map((item) => ({
            text: item,
            type: "circular",
          })),
        ];
      }

      setIncentiveData(tempIncentives);

      if(page.dupe_of) {
        const parent_page = await getPageByObjId(page.dupe_of);
        if(parent_page.success) {
          console.log('parent_page', parent_page.payload);
          setparentPageData(parent_page.payload);
          if (parent_page.payload.crop_approved) {
            setIsApproveDisabled(false);
          } else {
            setIsApproveDisabled(true);
          }

          const parent_page_circular = await getCircularByObjID(parent_page.payload.circular_id);
          if(parent_page_circular.success) {
            console.log('parent_page circular', parent_page_circular.payload);
            setparentPageCircularData(parent_page_circular.payload);
          }else{
            //Error 
          }       

        }else{
          //Error 
        }
      }

      if (page?.filename) {
        setCanvasErrorMessage('');
        let filename = page.filename;
        if (page.is_doubletruck){
          setIsDoubleTruck(true);
          filename = page.doubletruck_filename;
        } else {
          setIsDoubleTruck(false)
        }
        
        await fetchImage(filename, params, page.is_doubletruck);
        if (params) {
          setpagesData(pages);
        }
      } else {
        canvas.clear();
        setCanvasErrorMessage('This page needs to be redownloaded!');
      }
    }
    await postPageEvents("ready", "");
  };

  const navigateNext = async () => {
    const idx = pagesData.findIndex((item) => item.uuid === currentPageId);
    if (idx < pagesData.length - 1) {
      setCurrentPageId(pagesData[idx + 1]?.uuid);
      onPageSelect(pagesData[idx + 1]?.uuid, pagesData, pagesData[idx + 1]);
      canvas.zoomToPoint({ x: 0, y: 0 }, 1);
      await postPageEvents("click", "Next");
    } else {
      endQueueModal.openModal();
    }
  };


  const fetchImage = async (id, pageId, doubletruck) => {
    setIsFetching(true);

    // Check if the image is cached
    const cachedImage = await getCachedImage(id);
    if (cachedImage) {
      applyImage(cachedImage, pageId, doubletruck);
      return;
    }

    // Fetch the image from the API
    const img = await getImage(id);
    if (img.success) {
      // Cache the fetched image
      await setCachedImage(id, img.payload);
      applyImage(img.payload, pageId, doubletruck);
    } else {
      setIsFetching(false);
      //TODO: If image doesnt load show and error
    }
  };

  const applyImage = async (base64Image, pageId, doubletruck) => {
    canvas.clear();
    canvas.zoomToPoint({ x: 0, y: 0 }, 1);
    let vpt = canvas.viewportTransform;
    vpt[4] = 0;
    vpt[5] = 0;

    var background = new Image();
    background.onload = async function () {
      setIsFetching(true);
      var f_img = new fabric.Image(background);
      setCanvasRatio(canvas.height / background.height);
      canvas.setBackgroundImage(f_img, canvas.renderAll.bind(canvas), {
        scaleX: canvas.height / background.height,
        scaleY: canvas.height / background.height,
      });

      let resultSections = await getPageSections(pageId);
      if (resultSections.success) {
        // setAdBlockData(results.payload.adblocks);
        drawAdblocks(
          canvas.height / background.height,
          resultSections.payload.sections,
          "section"
        );
      } else {
        //TODO: Warning, no adblocks created
      }

      let resultGroup = await getPageGroups(pageId);
      if (resultGroup.success) {
        // setAdBlockData(results.payload.adblocks);
        drawAdblocks(
          canvas.height / background.height,
          resultGroup.payload.groups,
          "group"
        );
      } else {
        //TODO: Warning, no adblocks created
      }

      let results = await getPageCrops(pageId);
      if (results.success) {

        //results.payload.adblocks.sort(sortAdblocks);
        results.payload.adblocks = customSort({ data: results.payload.adblocks, sortBy, sortField: 'type' });
        let blocks = results.payload.adblocks;
        blocks.forEach((block) => {
          let height = Math.abs(block.coords_y2 - block.coords_y1) * (canvas.height / background.height);
          let width = Math.abs(block.coords_x2 - block.coords_x1) * (canvas.height / background.height);
          if (Math.abs(width) < 20 || Math.abs(height) < 20) {
            block.crop_message = true;
          }
        });
        setAdBlockData(blocks);
        drawAdblocks(
          canvas.height / background.height,
          blocks,
          "adblock"
        );
      } else {
        //TODO: Warning, no adblocks created
      }

      if (doubletruck) {
        var line = new fabric.Line([(background.width / 2) / 4, 0, (background.width / 2) / 4, canvas.height], {
          stroke: 'green'
        });

        canvas.add(line);
        canvas.renderAll();
        setCanvas(canvas);
      }

      //Create the adblocks list
      var objects = canvas.getObjects();
      if (objects) {
        let canvasObjects = objects.map((object, index) => {
          return {
            index: index,
            type: object.type,
            uuid: object?.id,
            rejected: object?.rejected,
            approved: object?.approved,
            group_text: object?.group_text,
            group_type: object?.group_type,
            offer_text: object?.offer_text,
            offer_type: object?.offer_type,
            incentive_text: object?.incentive_text,
            incentive_type: object?.incentive_type,
            event_type: object?.event_type,
            event_text: object?.event_text,
            limited_time: object?.limited_time,
            start: object?.start,
            end: object?.end,
            reject_message: object?.reject_message,
            crop_message: object?.crop_message
          };
        });

        canvasObjects = customSort({ data: canvasObjects, sortBy, sortField: 'type' });
        //canvasObjects.sort(sortAdblocks)
        setCanvasObjects(canvasObjects);
      } else {
        setCanvasObjects([]);
      }
      setIsFetching(false);
    };

    background.src = "data:image/png;base64," + base64Image;
    canvas.renderAll();
    setCanvas(canvas);
    setIsFetching(false);
  };

  const drawAdblocks = (canvasRatio, adBlockData, type) => {
    // Draw block rect data if available

    if (adBlockData.length > 0) {
      adBlockData.forEach((adblock) => {
        var fill_color = "transparent";
        var stroke_color = currentOutlineColor;
        //if status is reject or approve display something diffrent
        if (adblock.rejected) {
          fill_color = "rgba(255,132,132,0.35)";
          stroke_color = "#FF8484";
        } else if (adblock.approved) {
          fill_color = "rgba(99,206,107,0.25)";
          stroke_color = "#63CE6B";
        }

        const rect = new fabric.Rect({
          left: adblock.coords_x1 * canvasRatio,
          top: adblock.coords_y1 * canvasRatio,
          height: (adblock.coords_y2 - adblock.coords_y1) * canvasRatio,
          width: (adblock.coords_x2 - adblock.coords_x1) * canvasRatio,
          stroke: stroke_color,
          strokeWidth: 1,
          fill: type === "adblock" ? fill_color : "#3BC7E320",
          cornerColor: "white",
          cornerStrokeColor: stroke_color,
          id: adblock.uuid,
          rejected: adblock.rejected,
          approved: adblock.approved,
          type: type,
          group_text: type === "group" ? adblock?.group_text || "" : "",
          group_type: type === "group" ? adblock?.group_type || "" : "",
          offer_text: type === "group" ? adblock?.offer_text || null : "",
          offer_type: type === "group" ? adblock?.offer_type || null : "",
          incentive_text: type === "group" ? adblock?.incentive_text || null : "",
          incentive_type: type === "group" ? adblock?.incentive_type || null : "",
          event_type: type === "group" ? adblock?.event_type || null : "",
          event_text: type === "group" ? adblock?.event_text || null : "",
          limited_time: type === "group" ? adblock?.limited_time || null : "",
          start: type === "group" ? adblock?.start || null : "",
          end: type === "group" ? adblock?.end || null : "",
          reject_message: adblock?.reject_message,
          crop_message: adblock.hasOwnProperty('crop_message') ? adblock?.crop_message : false
        });
        rect.setControlsVisibility({ mtr: false });
        canvas.add(rect);
      });
      canvas.renderAll();
      setCanvas(canvas);
    }
  };

  /*
  ///////////////////////////////////////
  ////// Page Flags //////////////////
  /////////////////////////////////////
  */

  const handleUnsetDoubletruck = async () => {
    setIsSubmitting(true);
    try {
      const result = await unsetDoubletruckPage(currentPageId);
      if(result.success) {
        //Reload page
        window.location.reload();
        //navigateNext();
    
      }else{
        // Log error message
      }
      
    } catch (error) {
        // Error
    }
    setIsSubmitting(false);
  };

  const handleDoubletruck = async () => {
    setIsSubmitting(true);
    try {
      const result = await createDoubletruckPage(currentPageId);
      if(result.success && result.payload.errors === "") {

        //console.log('result', result);
        fetchImage(result.payload.message, currentPageId, true);

        let page_index = pagesData.findIndex((item) => item?.uuid === currentPageId)
        pagesData[page_index].is_doubletruck = true;
        pagesData[page_index].doubletruck_filename = result.payload.message;
        // Set the page object as double truck

        setIsDoubleTruck(true);
    
      }else{
        setErrorText(result.payload.errors);
        alertModal.openModal();
      }
      
    } catch (error) {
      
    }
    setIsSubmitting(false);
  };

  const handleDoubletruckVertical = async () => {
    setIsSubmitting(true);
    try {
      const result = await createDoubletruckPageVerical(currentPageId);
      if(result.success && result.payload.errors === "") {

        //console.log('result', result);
        fetchImage(result.payload.message, currentPageId, true);

        let page_index = pagesData.findIndex((item) => item?.uuid === currentPageId)
        pagesData[page_index].is_doubletruck = true;
        pagesData[page_index].doubletruck_filename = result.payload.message;
        // Set the page object as double truck

        setIsDoubleTruck(true);
    
      }else{
        setErrorText(result.payload.errors);
        alertModal.openModal();
      }
      
    } catch (error) {
      
    }
    setIsSubmitting(false);
  };

  const handleReject = async () => {
    setIsSubmitting(true);
    let data = {};
    try {
      const result = await rejectPage(currentPageId, data);
      if(result.success) {

        let page_index = pagesData.findIndex((item) => item?.uuid === currentPageId)
        pagesData[page_index].rejected = true;
        pagesData[page_index].is_doubletruck = false;

        setIsDoubleTruck(false)
        setAdBlockData([]);
        setCanvasObjects([]);
        canvas.clear();
        navigateNext();
    
      }else{
      // Log error message
      }
      
    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  const handleRequeue = async () => {
    setIsSubmitting(true);
    let data = {};
    try {
      await addPageToQueue(currentPageId, data);
    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    const result = await deletePageById(currentPageId);
    if (result.success) {
      window.location.reload();
    } else {
      // Error
    }
    deletePageModal.onClose();
    setIsSubmitting(false);
  }

  const handleApprove = async () => {
    setIsSubmitting(true);
    const crops = canvas.getObjects();
    const adblocks = crops
      .filter((crop) => crop.type === "adblock")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }

        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
          rejected: false
        };
      });
      const sections = crops
      .filter((crop) => crop.type === "section")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }

        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
        };
      });
    const groups = crops
      .filter((crop) => crop.type === "group")
      .map((crop) => {
        var uuid;

        if (crop?.id.indexOf("temp") !== -1 || crop?.id === undefined) {
          uuid = null;
        } else {
          uuid = crop?.id;
        }
        return {
          coords_x1:
            Math.min(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_x2:
            Math.max(crop.aCoords.tl.x, crop.aCoords.br.x) / canvasRatio,
          coords_y1:
            Math.min(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          coords_y2:
            Math.max(crop.aCoords.tl.y, crop.aCoords.br.y) / canvasRatio,
          uuid: uuid,
          offer_type: crop.offer_type,
          offer_text: crop.offer_text,
          event_type: crop.event_type,
          event_text: crop.event_text ? Array.isArray(crop.event_text) ? crop.event_text.join() : crop.event_text : '',
          incentive_type: crop.incentive_type,
          incentive_text: crop.incentive_text,
          limited_time: crop.limited_time,
          start: crop.start ? crop.start : null,
          end: crop.end ? crop.end : null,
        };
      });

    if (incentiveData.filter(({ type }) => type === "page").length > 0) {
      await savePageIncentiveString(
        currentPageId,
        incentiveData
          .filter(({ type }) => type === "page")
          .map((item) => item.text)
          .join(",")
      );
    }
    if (incentiveData.filter(({ type }) => type === "circular").length > 0) {
      await saveCircularIncentiveString(
        currentCircularId,
        incentiveData
          .filter(({ type }) => type === "circular")
          .map((item) => item.text)
          .join(",")
      );
    }
    try {
      const result = await approvePage(currentPageId, { adblocks, groups, sections, digital_coupon_f: digitalCouponApplied });
      if(result.success) {
          const res = await getCurrentUserAPI();
          if (res.success) {
            setLiveCounterData(res.results);
          }
          let page_index = pagesData.findIndex((item) => item?.uuid === currentPageId)
          pagesData[page_index].crop_approved = true;

          setIsDoubleTruck(false)
          setAdBlockData([]);
          setCanvasObjects([]);
          setDigitalCouponApplied(false);
          canvas.clear();
          navigateNext();
      
        }else{
        // Log error message
      }

    } catch (error) {
      setErrorText(error.toString());
      alertModal.openModal();
    }
    setIsSubmitting(false);
  };

  /*
  ///////////////////////////////////////
  ////// Auto Crop //////////////////
  /////////////////////////////////////
  */

  const handleAutoCrop = async (force = false) => {
    setIsAdCropping(true);
    const result = await postPageAutoCrop(currentPageId, force);
    if (result.success) {
      fetchImage(pageData.filename, currentPageId, result.payload.adblocks, false);
    } else {
      //TODO: set auto crop fail here
    }
    setIsAdCropping(false);
  };

  /*
  ///////////////////////////////////////
  ////// Canvas Tools //////////////////
  /////////////////////////////////////
  */

  const handleClickAdblockType = () => {
    setType("adblock");
  };
  const handleClickSectionType = () => {
    setType("section");
  };
  const handleClickGroupType = () => {
    setType("group");
  };

  const handleCanvisObjectMouseOver = (canvi, event) => {
    if (event?.target && canvi.status !== "crop") {
      if (event.target === canvi.getActiveObject()) {
        event.target.hoverCursor = "move";
      }
      event.target.set("strokeDashArray", [5]);
      canvi.renderAll();
    }
  };

  /*
  ///////////////////////////////////////
  ////// Adblock Methods  //////////////////
  /////////////////////////////////////
  */

  function deleteFromList(selected) {

    //Find the object in the list, create new list
    var objects = canvas.getObjects();
    let newCanvasObjects = objects
    .filter((item) => item.id !== selected.uuid)
    .map((object, index) => {
      return {
        index: index,
        uuid: object?.id,
        type: object?.type,
        text: object?.text,
        rejected: object?.rejected,
        approved: object?.approved,
        group_type: object?.group_type,
        group_text: object?.group_text,
        reject_message: object?.reject_message,
        crop_message: object?.crop_message
      };
    });

    const seletedIndex = objects.findIndex(
      (item) => item.id === selected.uuid
    );

    //select the object first
    canvas.setActiveObject(canvas.item(seletedIndex));
    setCurrentAdblockId("");

    // //delete the object
    canvas.remove(canvas.item(seletedIndex));

    //Update the new list after deletion
    canvas.requestRenderAll();
    newCanvasObjects = customSort({data:newCanvasObjects, sortBy, sortField: 'type'});
    //newCanvasObjects.sort(sortAdblocks)
    setCanvasObjects(newCanvasObjects);
  }
  
  async function handleListDelete(selected) {
    setIsDeleting(true);
    if(selected.type === "adblock") {

      if(selected.uuid.includes('temp')) {
        deleteFromList(selected);
      }else{
        const result = await deleteAdBlockById(selected.uuid);
        if (result.success) {
          
          deleteFromList(selected);
  
        } else {
          //TODO: failed to delete adblock
        }
      }

    }else if(selected.type === "section"){

      if(selected.uuid.includes('temp')) {
        deleteFromList(selected);
      }else{
        const result = await deleteSectionById(selected.uuid);
        if (result.success) {
          
          deleteFromList(selected);
          
        } else {
          //TODO: failed to delete adblock
        }
      }
      
    }else{
      
      if(selected.uuid.includes('temp')) {
        deleteFromList(selected);
      }else{
        const result = await deleteGroupById(selected.uuid);
        if (result.success) {
          console.log(selected, result)
          deleteFromList(selected);
        } else {
          //TODO: failed to delete adblock
        }
      }

    }
    setIsDeleting(false);
  }

  function removeItemFromSingle(activeCanvasObject) {
    
      //Find the object in the list, create new list
      var objects = canvas.getObjects();
      let newCanvasObjects = objects.filter(
        (item) => item.id !== activeCanvasObject.id
      );

      newCanvasObjects = newCanvasObjects.map((object, index) => {
        return {
          index: index,
          uuid: object?.id,
          type: object?.type,
          text: object?.text,
          rejected: object?.rejected,
          approved: object?.approved,
          group_type: object?.group_type,
          group_text: object?.group_text,
          reject_message: object?.reject_message,
          crop_message: object?.crop_message
        };
      });

      // //delete the object
      canvas.remove(activeCanvasObject);
      canvas.requestRenderAll();

      newCanvasObjects = customSort({data:newCanvasObjects, sortBy, sortField: 'type'});
      //Update the new list after deletion
      //newCanvasObjects.sort(sortAdblocks)
      setCanvasObjects(newCanvasObjects);
  }

  function handleDeleteBlock() {
    setIsDeleting(true);
    if (currentCanvasObjects.length > 0) {
      handleMultiDelete();
    } else {
      handleSingleDelete(activeCanvasObject);
    }
    setIsDeleting(false);
  }

  async function handleSingleDelete(obj) {

    if(obj.type === "adblock") {

      if(obj.id?.includes('temp')) {
        removeItemFromSingle(obj);
      }else{
        const result = await deleteAdBlockById(obj.id);
        if (result.success) {
          
          removeItemFromSingle(obj);

        } else {
          //TODO: failed to delete adblock
        }
      }

    }else if(obj.type === "section"){

      if(obj.id?.includes('temp')) {
        removeItemFromSingle(obj);
      }else{
        const result = await deleteSectionById(obj.id);
        if (result.success) {
          
          removeItemFromSingle(obj);

        } else {
          //TODO: failed to delete adblock
        }
      }

    }else{

      if(obj.id?.includes('temp')) {
        removeItemFromSingle(obj);
      }else{
        // const result = await deleteSectionById(activeCanvasObject.id);
        // if (result.success) {
          
        //   removeItemFromSingle(activeCanvasObject);

        // } else {
        //   //TODO: failed to delete adblock
        // }
      }

    }

  }

  async function handleMultiDelete() {
    currentCanvasObjects?.map(async obj => {
      await handleSingleDelete(obj)
    });
    
    setCurrentCanvasObjects([]);
    canvas.discardActiveObject();
    canvas.renderAll(); 
  }

  const handleAdblockSelect = async (uuid, index) => {
    if (index < canvas.getObjects().length) {
      setCurrentAdblockId(uuid);
      canvas.setActiveObject(canvas.item(index));
      canvas.requestRenderAll();
      setSelectedIndex(index);

      await postPageEvents("click", `Adblock ${index}`);
    }
  };

  const handlePickColor = async (color) => {
    setCurrentOutlineColor(color);
    canvas.getObjects().map(function (blocks) {
      var stroke_color = color;
      //if status is reject or approve display something diffrent
      if (blocks.rejected) {
        stroke_color = "#FF8484";
      } else if (blocks.approved) {
        stroke_color = "#63CE6B";
      }

      return blocks.set({
        stroke: stroke_color,
        cornerStrokeColor: stroke_color,
      });
    });
    canvas.requestRenderAll();
    await postPageEvents("click", `Color ${color}`);
  };

  const handleClickAutoCrop = async () => {
    await postPageEvents("click", `Auto Crop`);
    if (adBlockData.length > 0) {
      retryCroppingModal.openModal();
    } else {
      handleAutoCrop(false);
    }
  };

  const handleChangeText = (id, group_text) => {

    var objects = canvas.getObjects();
    let newCanvasObjects = objects.map((object, index) => {
      let data = {
        index: index,
        type: object?.type,
        uuid: object?.id,
        rejected: object?.rejected,
        approved: object?.approved,
        group_text: object?.group_text,
        group_type: object?.group_type,
        reject_message: object?.reject_message,
        crop_message: object?.crop_message
      };
      if(object?.type === 'group' &&  object.id === id) {
        data = {
          ...group_text,
          ...data
        }
        object.set("offer_text", group_text.offer_text);
        object.set("offer_type", group_text.offer_type);
        object.set("incentive_text", group_text.incentive_text);
        object.set("incentive_type", group_text.incentive_type);
        object.set("event_type", group_text.event_type);
        object.set("event_text", group_text.event_text);
        object.set("limited_time", group_text.limited_time);
        object.set("start", group_text.start);
        object.set("end", group_text.end);
      }
      return data;
    })

    newCanvasObjects = customSort({data:newCanvasObjects, sortBy, sortField: 'type'});
    //newCanvasObjects.sort(sortAdblocks);
    setCanvasObjects(newCanvasObjects);

  };
  
  const handleChangeSelect = (id, group_type) => {

    var objects = canvas.getObjects();
    let newCanvasObjects = objects.map((object, index) => {
      let data = {
        index: index,
        type: object?.type,
        uuid: object?.id,
        rejected: object?.rejected,
        approved: object?.approved,
        group_text: object?.group_text,
        group_type: object?.group_type,
        reject_message: object?.reject_message,
        crop_message: object?.crop_message
      };

      if(object?.type === 'group' &&  object.id === id) {
        data.group_text =  object?.group_text;
        data.group_type = group_type;            
        object.set('group_type', group_type);
      }

      return data;
    })


    newCanvasObjects = customSort({data:newCanvasObjects, sortBy, sortField: 'type'});
    //newCanvasObjects.sort(sortAdblocks);
    setCanvasObjects(newCanvasObjects);

  };

  /*
  ///////////////////////////////////////
  ////// Keyboard events  //////////////////
  /////////////////////////////////////
  */

  useKeypress(["Escape"], (event) => {
    canvas.discardActiveObject();
    canvas.requestRenderAll();
    setCurrentAdblockId("");
  });

  useKeypress(["Delete"], (event) => {
    if (currentAdblockId) {
      handleListDelete({ uuid: currentAdblockId }, selectedIndex);
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {!(userInfo.user.is_superuser || userInfo?.user.is_moderator) ? (
                  <>
                    <dvi className="ml-1 my-auto">Circulars</dvi>
                    <div className="ml-2 my-auto">{circularName}</div>
                  </>
                ) : (
                  <>
                    <Link href="/circular/" className="ml-1 my-auto">
                      Circulars
                    </Link>
                    <Link
                      href={`/circular/${currentCircularId}/${currentPageId}`}
                      className="ml-2 my-auto"
                    >
                      {circularName}
                    </Link>
                  </>
                )}

                <PageDropDown
                  className="ml-3"
                  id={currentCircularId}
                  onSelectValue={onPageSelect}
                  value={currentPageId}
                  isVisible={userInfo?.user?.is_superuser || userInfo?.user?.is_moderator || userInfo?.user?.is_active}
                />

                 <PageType className="ml-2 my-auto">
                    {pageType}
                  </PageType>

              </div>
              <PageInfo className="ml-auto my-auto ">{`Page ${
                pagesData.findIndex((item) => item?.uuid === currentPageId) < 0
                  ? 0
                  : pagesData.findIndex(
                      (item) => item?.uuid === currentPageId
                    ) + 1
              }/${pagesData.length}`}</PageInfo>

              {/* {userInfo.user?.is_superuser ? */}
              <>
                <Button
                  buttonTheme={"dark"}
                  width="100px"
                  size="small"
                  className="ml-3 my-auto"
                  onClick={handleRequeue}
                  isLoading={isSubmitting}
                  eventType="flag"
                  disabled={isFetching || isLoading}
                >
                  Queue
                </Button>
                
                {pageData.is_doubletruck ?
                  <Button
                    buttonTheme={"dark"}
                    width="160px"
                    size="small"
                    className="ml-2 my-auto"
                    onClick={handleUnsetDoubletruck}
                    isLoading={isSubmitting}
                    eventType="flag"
                    disabled={isFetching || isLoading}
                  >
                    Unset Doubletruck
                  </Button> 
                    : <DropDownMenu
                      title="DoubleTruck"
                      isLoading={isSubmitting}
                      disabled={isFetching || isLoading}
                    >
                        <MenuItem
                          buttonTheme={"dark"}
                          width="150px"
                          size="small"
                          onClick={() => handleDoubletruck()}
                          disabled={isFetching || isLoading}
                        >
                          Horizontal <VscSplitHorizontal />
                        </MenuItem>
                        <MenuItem
                          buttonTheme={"dark"}
                          width="150px"
                          size="small"
                          onClick={() => handleDoubletruckVertical()}
                          disabled={isFetching || isLoading}
                        >

                          Vertical <VscSplitVertical />
                        </MenuItem>
                      </DropDownMenu>
                  }

                <Button
                  buttonTheme={`${pageData.rejected ? "error" : "dark"}`}
                  width="100px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={() => 
                    {
                      if (!pageData.rejected){
                        rejectPageModal.openModal();
                      } else {
                        deletePageModal.openModal();
                      }
                    }
                  }
                  isLoading={isSubmitting}
                  eventType="flag"
                  disabled={isFetching || isLoading}
                >
                  {pageData.rejected ? "DELETE" : "REJECT"}
                </Button>
              </>
              {/* : null } */}
              
              <Button
                buttonTheme={`${pageData.crop_approved ? "green" : "dark"}`}
                width="120px"
                size="small"
                className="ml-2 my-auto"
                onClick={() => {
                  if (canvasObjects.length > 0){
                    handleApprove()
                  } else {
                    approvePageWithNoAdblockModal.openModal()
                  }
                }}
                isLoading={isSubmitting}
                eventType="flag"
                disabled={isFetching || isLoading || isApproveDisabled}
              >
                {pageData.crop_approved ? "APPROVED" : "APPROVE"}
              </Button>
            </div>
          )}
        </Header>

        <Content>
          <ToolContent>
            <AdblockToolButton
              onClick={handleClickAdblockType}
              type="Adblock"
              isActive={type === "adblock"}
            />
            <AdblockToolButton
              onClick={handleClickGroupType}
              type="Group"
              isActive={type === "group"}
            />
            <AdblockToolButton
              onClick={handleClickSectionType}
              type="Section"
              isActive={type === "section"}
            />
          </ToolContent>
          <div className="d-flex flex-1">
            <SelectionContainer>
              <SelectionHeader>
                PAGE ADBLOCK CROPPING
                {isDoubleTruck ? 
                  <Status info>
                    Double Truck
                  </Status>
                : null}
                {pageData.is_dupe
                  ? (
                  <>
                    <Status>VARIANT</Status>

                    {parentPageData.crop_approved && !pageData.crop_approved ?
                      <Status info>Queued for Processing</Status>
                    : null}
                    
                    <Link className={'link'} target="_blank" href={location.origin + "/adblock/" + parentPageCircularData?.uuid + "/" + parentPageData?.uuid}>View Parent</Link>

                  </>
                  )
                : null}

                <div className="ml-auto d-flex">
                  {OUTLINE_COLORS.map((color) => {
                    return (
                      <ColorPick
                        bgColor={color}
                        key={color}
                        active={color === currentOutlineColor}
                        onClick={() => handlePickColor(color)}
                      />
                    );
                  })}
                  {!isAdCropping && !isDoubleTruck ? (
                    <MdRefresh
                      className="refresh ml-2 my-auto"
                      size={18}
                      onClick={handleClickAutoCrop}
                    />
                  ) : null}
                  {activeCanvasObject ? (
                    !isDeleting ?
                      <FaTrash
                        className="delete ml-2 my-auto mr-2"
                        onClick={handleDeleteBlock}
                      /> :
                      <FaHourglass
                        className="delete ml-2 my-auto mr-2"
                      />
                  ) : null}
                </div>
              </SelectionHeader>

              {isAdCropping || isFetching || isSubmitting ? (
                <CanvasOverlay>
                  {isAdCropping ? ( //TODO: we can rotate text and make it look like it is processing
                    <AutoCropping height={canvas.height} width={canvas.width}>
                      Recropping adblocks for this page, please do not refresh
                      or close the page. This process can take a minute to 90
                      seconds.
                    </AutoCropping>
                  ) : null}

                  {isFetching ? (
                    <SkeletonContainer>
                      <Skeleton height={canvas.height} width={canvas.width} />
                    </SkeletonContainer>
                  ) : null}
                </CanvasOverlay>
              ) : null}

              <CanvasContainer className="w-100" ref={blockRef}>
                <div>
                  {canvasErrorMessage ?
                    <div className="pt-4 mt-4 mx-auto text-center color-white">
                      {canvasErrorMessage}
                    </div> : ''}
                </div>
                <Canvas id="canvas" />
              </CanvasContainer>
            </SelectionContainer>
          </div>
          <RightSection>
            <DigitalCouponCheck 
              value={digitalCouponApplied}
              onChange={(e) => {
                setDigitalCouponApplied(e)
              }}
            />
            <AdblockIncentives
              onAdd={handleAddIncentive}
              onDelete={handleRemoveIncentive}
              onUpdate={handleUpdateIncentive}
              data={incentiveData}
            />
            <AdblocksContainer>
              <SelectionHeader>
                {canvasObjects.length} <span>({ canvasObjects.filter((currentItem) => currentItem.rejected === true).length})</span> ADBLOCKS
              </SelectionHeader>
              <SelectionContent>
                {isLoading || isSubmitting || isFetching ? (
                  <Skeleton count={10} height={40} style={{ marginTop: 8 }} />
                ) : (
                  <>
                    {canvasObjects.map((item, index) =>
                      item.type === "adblock" ? (
                        <AdblockItem
                          data={item}
                          key={index}
                          onDelete={handleListDelete}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleAdblockSelect(item.uuid, index)}
                          isDeleting={isDeleting}
                        />
                      ) : item.type === "section" ? (
                        <SectionItem
                          data={item}
                          key={index}
                          onDelete={handleListDelete}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleSectionSelect(item.uuid, index)}
                          isDeleting={isDeleting}
                        />
                      ) : item.type === "group" ? (
                        <GroupItem
                          data={item}
                          key={index}
                          index={index}
                          active={item.uuid === currentAdblockId}
                          onClick={() => handleGroupSelect(item.uuid, index)}
                          onChangeText={(e) => handleChangeText(item.uuid, e)}
                          onChangeSelect={(e) => handleChangeSelect(item.uuid, e)}
                          onDelete={(e) => handleListDelete(item)}
                          isDeleting={isDeleting}
                        />
                      ) : null
                    )}
                  </>
                )}
              </SelectionContent>
            </AdblocksContainer>
          </RightSection>
        </Content>
        <Alert {...alertModal} title="Error" size="small">
          {errorText}
        </Alert>
        <EndQueueModal circularId={currentCircularId} {...endQueueModal} />
        <RetryCroppingModal
          onOk={() => handleAutoCrop(true)}
          {...retryCroppingModal}
        />
        <RejectPageWarning
          {...rejectPageModal}
          onReject={() => handleReject()}
        />
        <ApprovePageNoAdblockWarning
          {...approvePageWithNoAdblockModal}
          onApprove={() => handleApprove()}
        />
        <DeleteWarningModal
        {...deletePageModal} 
        onDelete={() => handleDelete()} 
      />
      </MainLayout>
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 14px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
  flex-grow: 0;
  flex-shrink: 0;
`;

const ToolContent = styled.div`
  width: 64px;
  color: white;
  display: flex;
  flex-direction: column;
  background: ${(props) => props.theme.palette.backgrounds};
  margin: 2px 2px;
  padding: 8px 0;
`;

const SkeletonContainer = styled.div`
  margin-top: -4px;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const SelectionContainer = styled.div`
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  flex: 1;
  position: relative;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;
  margin-bottom: 5px;
  display: flex;
  span {
    padding:0 5px;
    color:${(props) => props.theme.palette.error};
    font-size: 0.8em;
  }

  svg {
    color: white;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }

  .link {
    margin:0 10px;
  }

`;

const SelectionContent = styled.div`
  text-transform: uppercase;
  overflow: auto;
  height:100%;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const PageType = styled.div`
  padding: 5px;
  font-size: 14px;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

const AdblocksContainer = styled.div`
  min-width: 320px;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

const CanvasContainer = styled.div`
  height: calc(100% - 30px) !important;
  background-color: ${(props) => props.theme.palette.items};
`;

const CanvasOverlay = styled.div`
  height: calc(100% - 30px) !important;
  position: absolute;
  z-index: 1;
`;

const AutoCropping = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  background-color: ${(props) => props.theme.palette.items};
  padding: 20px;
  color: ${(props) => props.theme.palette.white};
`;

const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;


const Status = styled.div`
  margin: 0px 0 4px 10px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 12px;
  height: 22px;
  padding: 5px 8px;
  background: ${(props) =>
    props.approved
      ? props.theme.palette.success
      : props.rejected
      ? props.theme.palette.error
      : props.warning
      ? props.theme.palette.warning
      : props.info
      ? props.theme.palette.info:
      props.theme.palette.warning};
  color: ${(props) =>
    props.approved || props.rejected
      ? props.theme.palette.white
      : props.theme.palette.background};
`;


const MenuItem = styled(Button)`
    margin-top: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

