/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useDebounce } from "use-debounce";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { useMainContext } from "contexts";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import "./style.scss";
import useKeypress from "react-use-keypress";

export const SearchableUnit = ({
  id = "",
  value,
  onChange,
  label,
  className,
  backgroundColor = "#eee",
  placeholder = "",
  width,
  idField = "id",
  inputId,
  displayValue = "",
  func = () => {},
  onSelectId = () => {},
  onSelectValue = () => {},
  onClick = () => {},
  onFocus = () => {},
  data = [],
  catUnits = [],
  // otherUnitsLen
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search, 500);
  const [innerValue, setInnerValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(null);
  const { postPageEvents, activeField } = useMainContext();

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params) => {
    if (params !== value) {
      setSearch("");
      onChange(params[idField]);
      onSelectId(params[idField]);
      setInnerValue(params.title);
      onSelectValue(params);
      postPageEvents("click", id || label);
    }
    setShowResults(false);
  };

  const handleBlur = (event) => {
    setFocused(false);
    onFocus(false);
    setCurrentIndex(null);
    postPageEvents("blur", id || label);
    setTimeout(() => {
          setShowResults(false);
      }, 100);
  };

  const handleClear = () => {
    onChange("");
    setSearch("");
    onSelectId("");
    setInnerValue("");
    onSelectValue("");
    setCurrentIndex(null);
  };
  
  useEffect(() => {    
    if (activeField !== inputId && innerValue) {
      setShowResults(false);
    }
    return () => {};
  }, [activeField, inputId]);

  useEffect(() => {
    if (debouncedValue) {
    } else {
      setShowResults(false);
    }
    return () => {};
  }, [debouncedValue]);

  useEffect(() => {
    if (displayValue) {
      setInnerValue(displayValue);
    }
    return () => {};
  }, [displayValue]);

  useEffect(() => {
    setInnerValue(data.find((item) => item.id === value)?.title || "");
    return () => {};
  }, [value]);

  useEffect(() => {
    if (search) {
      setShowResults(true);
    }
    return () => {};
  }, [search]);

  useKeypress(["ArrowUp"], (event) => {
    if(focused)
    { 
      event.preventDefault();
      if(currentIndex === null)
      {
        setCurrentIndex(0);
      }
      else if(currentIndex > 0 && currentIndex < (data.length)) {
        setCurrentIndex(currentIndex - 1);
      }
    }
  });

  useKeypress(["ArrowDown"], (event) => {
    console.log(focused);
    if(focused) {
      event.preventDefault();
      console.log(currentIndex);
      if(currentIndex === null) {
        setCurrentIndex(0);
      }
      else if(currentIndex < (data.length - 1)) {
        setCurrentIndex(currentIndex + 1); 
      }
    }
  });
  
  useKeypress(["Enter"], (event) => {
    if(focused) {
      event.preventDefault();
      if(currentIndex !== null && document.getElementsByClassName("selectedidx").length > 0){
       var input = document.getElementsByClassName("selectedidx")[0];
       input.click();
      }
    }
  });

  useEffect(() => {
    if(document.getElementsByClassName("selectedidx").length > 0){
      document.getElementsByClassName("selectedidx")[0].scrollIntoView({block: "nearest", inline: "nearest"});
    }
  }, [currentIndex]);

  return (
    <div className={className} onClick={onClick} >
      {label && (
        <span className="searchable-drop-down-label my-auto">{label}</span>
      )}
      <div className="searchable-drop-down" style={{ width }}>
        <div
          className="menu-button"
          onClick={() => setShowResults((state) => !state)}
          style={{ width }}
        >
          {innerValue ? (
            <>
              <InnerValue className="mr-auto" 
                id={inputId} value={innerValue} 
                onFocus={() => {
                  setShowResults((state) => !state);
                  setFocused(true);
                }}
                onBlur={handleBlur}
                readOnly />
              <button
                className="menu-button__drop-down"
                onClick={() => handleClear()}
              >
                <AiOutlineClose color="white" alt="clear" />
              </button>
            </>
          ) : (
            <div className="d-flex w-100">
              <StyledInput
                type="text"
                id={inputId || Math.random(100)}
                aria-labelledby={"auto complete"}
                autocomplete="chrome-off"
                onFocus={() => {
                  setShowResults(true);
                  setFocused(true);
                  onFocus(true);
                  postPageEvents("focus", id || label);
                }}
                onBlur={handleBlur}
                aria-describedby={"auto complete"}
                value={search}
                placeholder={placeholder}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              <AiFillCaretDown
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              />
            </div>
          )}

          <Underline focused={focused} />
        </div>
        {showResults && (
          <>
            <div ref={menuRef} className="menu-dropdown">
              {catUnits.length > 0 && search === '' &&
                <>
                  <div className="menu-title">
                    Category Units
                  </div>
                  {catUnits.map((item, index) => {
                    return (
                      <div
                        className={classNames(
                          "menu-item",
                          item.title === value ? "active" : "",
                          index === currentIndex ? "selectedidx" : ""
                        )}
                        key={`key${index}`}
                        onClick={() => handleChange(item)}
                        style={{ width }}
                      >
                        {item.title}
                      </div>
                    );
                  })}
                </>
              }
              {search === '' &&
                <div className="menu-title">
                  All Units
                </div>}
              {data.filter((item) =>
                item.title.toLowerCase().includes(search.toLowerCase())
              ).length > 0 ? (
                data
                  .sort((a, b) => a.title.localeCompare(b.title))
                  .filter((item) =>
                    item.title.toLowerCase().includes(search.toLowerCase())
                  )
                  .map((item, index) => {
                    return (
                      <div
                        className={classNames(
                          "menu-item",
                          item.title === value ? "active" : "",
                          (index + catUnits.length) === currentIndex ? "selectedidx" : ""
                        )}
                        key={`key${index}`}
                        onClick={() => handleChange(item)}
                        style={{ width }}
                      >
                        {item.title}
                      </div>
                    );
                  })
              ) : (
                <div className="my-3 d-flex justify-content-center">
                  No results
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: calc(100% - 24px);
  &:focus {
    border: none;
    outline: none;
  }
`;
