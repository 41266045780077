import { INCENTIVE_TYPE_DATA } from "config";
import { DURATION_MIN, DURATION_MAX } from "config";
// import { incentiveTypeData } from "./data";

export const abbreviateName = (name = "") => {
  return name
    ? name
      // split the name into two words
      .split(" ")

      // take first char of both parts and capitalize them
      .map((part) => part[0].toUpperCase())

      // join both characters with a dot
      .join("")
      .slice(0, 2)
    : "";
};

export const extractTagFromQuery = (query = "", name = "") => {
  const startIndex = query.indexOf(name);
  if (startIndex === -1) {
    return { tag: "", rest: query };
  } else {
    let lastIndex = startIndex + query.slice(startIndex).indexOf(" ");
    if (lastIndex === -1) {
      lastIndex = query.length;
    }
    return {
      tag: query.slice(startIndex + name.length, lastIndex).trim(),
      rest: query.slice(0, startIndex).trimLeft() + query.slice(lastIndex),
    };
  }
};

export const parseQuery = (query) => {
  const { tag: uploadDates, rest: query1 } = extractTagFromQuery(
    query,
    "upload-time:"
  );

  const { tag: creationDates, rest: query2 } = extractTagFromQuery(
    query1,
    "creation-time:"
  );

  const { tag: duration, rest: query3 } = extractTagFromQuery(
    query2,
    "duration:"
  );

  let tags = [];
  let subTags = [];
  let search = query3;
  do {
    const { tag: resTags, rest } = extractTagFromQuery(search, "tag:");
    subTags = resTags.split(",").filter((a) => a.trim().length);
    tags = [...tags, ...subTags];
    search = rest;
  } while (subTags.length > 0);

  subTags = [];
  do {
    const { tag: resTags, rest } = extractTagFromQuery(search, "target:");
    subTags = resTags.split(",").filter((a) => a.trim().length);
    tags = [...tags, ...subTags.map((a) => `target.${a}`)];
    search = rest;
  } while (subTags.length > 0);

  subTags = [];
  do {
    const { tag: resTags, rest } = extractTagFromQuery(search, "class:");
    subTags = resTags.split(",").filter((a) => a.trim().length);
    tags = [...tags, ...subTags.map((a) => `class.${a}`)];
    search = rest;
  } while (subTags.length > 0);

  return {
    uploadDates:
      uploadDates.split("..").length === 2 ? uploadDates.split("..") : ["", ""],
    creationDates:
      creationDates.split("..").length === 2
        ? creationDates.split("..")
        : ["", ""],
    duration:
      duration.split("..").length === 2
        ? {
          min: parseInt(duration.split("..")[0]),
          max: parseInt(duration.split("..")[1]),
        }
        : { min: DURATION_MIN, max: DURATION_MAX },
    tags: tags.filter((a) => a.trim().length),
    search,
  };
};

export const validURL = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator
  return !!pattern.test(str);
};

export const extractTextBetweenFirstBalancedParenthesis = (text) => {
  let stack = [];
  for (let i = 0; i < text.length; i++){
    if (text[i] === '('){
      stack.push(i);
    }else if(text[i] === ')'){
      let start = stack.pop();
      if (stack.length === 0){
        return text.slice(start + 1, i);
      }
    }
  }
  return '';
}

export const splitIncentives = text => {
  const stack = [], splits = [];
  let bracket_count = 0, pointer = 0;
  for (let i = 0; i < text.length; i++){
    if (pointer > text.length) break;
    if (text[pointer] === ',') continue;
    if (text[i] === '(') stack.push('(');
    else if (text[i] === ')') {
      bracket_count++;
      stack.pop();
      if (bracket_count === 5 && stack.length === 0) {
        splits.push(text.slice(pointer, i + 1));
        bracket_count = 0;
        pointer = i + 2;
      }
    }
  }
  return splits;
}

export const splitIncentiveOnType = text => {
  const stringsToMatch = INCENTIVE_TYPE_DATA.map(item => item.title);
  // Create a regular expression pattern to find matches
  const pattern = new RegExp(`\\s*(?:${stringsToMatch.map(escapeRegExp).join('|')})`, 'i');

  // Function to escape special characters in regex
  function escapeRegExp(string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
  }

  // Split the text using regex to find matches and split at commas
  const splitParts = text.split(',');

  // Adjust splitting based on matches found
  let result = [];
  let currentSegment = '';
  
  splitParts.forEach(part => {
    const match = part.match(pattern);
    if (match) {
      if (currentSegment) {
        result.push(currentSegment.trim());
        currentSegment = '';
      }
      currentSegment += part;
    } else {
      currentSegment += ',' + part;
    }
  });

  // Push the last segment if exists
  if (currentSegment) {
    result.push(currentSegment.trim());
  }

  return result;
}

export const extractIncentivesInfo = text => {
  const regex = /\(.*?\)/g;
  const matches = text.match(regex);
  // Extract the specific matches

  if (!matches || matches.length < 3) {
    return [];
  }

  if (matches.length === 5) {
    const firstMatch = matches[0];
    const secondMatch = matches[matches.length - 4];
    const thirdMatch = matches[matches.length - 3];
    const forthMatch = matches[matches.length - 2];
    const lastMatch = matches[matches.length - 1];

    // Find the unmatched parts
    let unmatchedParts = '';
    const firstMatchIndex = text.indexOf(firstMatch);
    const secondMatchIndex = text.indexOf(secondMatch);

    // Between first and second last match
    const betweenFirstAndSecond = text.slice(firstMatchIndex + firstMatch.length, secondMatchIndex).trim();
    if (betweenFirstAndSecond) {
      unmatchedParts += betweenFirstAndSecond;
    }

    return {
      incentive_type: firstMatch.replace(/[()]/g, '').trim(),
      limit: secondMatch.replace(/[()]/g, '').trim(),
      net_cost: thirdMatch.replace(/[()]/g, '').trim(),
      by_mail: Number.parseInt(forthMatch.replace(/[()]/g, '').trim()),
      online_incentive: Number.parseInt(lastMatch.replace(/[()]/g, '').trim()),
      incentive_text: unmatchedParts
    };
  } else {
    const firstMatch = matches[0];
    const secondLastMatch = matches[matches.length - 2];
    const lastMatch = matches[matches.length - 1];

    // Find the unmatched parts
    let unmatchedParts = '';
    const firstMatchIndex = text.indexOf(firstMatch);
    const secondLastMatchIndex = text.indexOf(secondLastMatch);

    // Between first and second last match
    const betweenFirstAndSecondLast = text.slice(firstMatchIndex + firstMatch.length, secondLastMatchIndex).trim();
    if (betweenFirstAndSecondLast) {
      unmatchedParts += betweenFirstAndSecondLast;
    }

    return {
      incentive_type: firstMatch.replace(/[()]/g, '').trim(),
      limit: secondLastMatch.replace(/[()]/g, '').trim(),
      net_cost: lastMatch.replace(/[()]/g, '').trim(),
      incentive_text: unmatchedParts
    };
  }
}

export const getIncentiveInfo = text => {
  const splitTexts = splitIncentiveOnType(text);
  return splitTexts.map(split => extractIncentivesInfo(split));
}